import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import {  fetchChartData } from './utils';
import URLDataCard from './urldatacard';
import VariantEdit from './VariantEdit';
import { getPageListFromSettings } from './utils';
import VideoPlayer from './VideoPlayer';

const { Option } = Select;

const generateChartDataWithFakeDays = (originalChartData) => {
    let fakeChartData = [];
    if (originalChartData.length <= 1) {
        const originalData = originalChartData.length === 1 ? originalChartData[0] : { conversions: 0, views: 0 };
        for (let i = 0; i < 3; i++) {
            fakeChartData.push({
                ...originalData,
                day: i,
            });
        }
    } else {
        fakeChartData = originalChartData;
    }
    return fakeChartData;
};

const formatNumber = (num) => {
    return num > 999 ? `${(num / 1000).toFixed(1)}k` : num.toString();
};

const carbonOptions = {
    axes: {
        bottom: {
            title: "Day",
            mapsTo: "date",
            scaleType: "labels",
        },
        left: {
            mapsTo: "value",
            title: "Views",
            scaleType: "linear",
        },
        right: {
            mapsTo: "value",
            title: "Conversion Rate (%)",
            scaleType: "linear",
            align: 'right',
            formatter: value => `${value.toFixed(2)}%`,
            secondary: true,
        },
    },
    curve: "curveMonotoneX",
    height: "400px",
};

const URLDataContainer = ({ url, conversionData, carbonOptions, formatNumber }) => {
    const [currentView, setCurrentView] = useState('card');

    const switchToVariantEdit = () => {
        setCurrentView('variantEdit');
    };

    const switchToCard = () => {
        setCurrentView('card');
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {currentView === 'card' ? (
                <URLDataCard
                    url={url}
                    conversionData={conversionData}
                    carbonOptions={carbonOptions}
                    formatNumber={formatNumber}
                    onSwitchToVariantEdit={switchToVariantEdit}
                />
            ) : (
                <VariantEdit
                    initialUrl={url}
                    onClose={switchToCard}
                />
            )}
        </div>
    );
};

export default function VariantNext() {
    const [urls, setUrls] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const [urlChartData, setUrlChartData] = useState({});
    const [showWholeWebsiteWarning, setShowWholeWebsiteWarning] = useState(false);

    useEffect(() => {
        const fetchPageList = async () => {
            let pageList = await getPageListFromSettings();
            console.log('111 Page List from Settings:', pageList);
            if (pageList && pageList.length > 0) {
                // Remove the sorting by length
                // Previous: pageList.sort((a, b) => {
                //     if (a.includes("Whole website")) return -1;
                //     if (b.includes("Whole website")) return 1;
                //     return a.length - b.length;
                // });
    
                const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
                if (wholeWebsiteUrls.length > 1) {
                    const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
                    pageList = pageList.filter(url => !url.includes("Whole website"));
                    pageList.unshift(firstWholeWebsiteUrl);
                }
    
                setUrls(pageList);
    
                const wholeSiteUrl = pageList.find(url => url.includes("Whole website"));
                setSelectedUrl(wholeSiteUrl || pageList[0]);
                setShowWholeWebsiteWarning(!!wholeSiteUrl);
                console.log(`111 URL prioritized for loading: ${wholeSiteUrl || pageList[0]}`);
            } else {
                console.log("111 No URLs found");
            }
        };
        fetchPageList();
    }, []);

    useEffect(() => {
        const fetchChartDataForSelectedUrl = async () => {
            if (selectedUrl) {
                try {
                    let chartData = await fetchChartData(selectedUrl, 'default');
                    chartData = generateChartDataWithFakeDays(chartData);
                    
                    const chartDataWithRates = chartData.map(dataPoint => ({
                        ...dataPoint,
                        conversionRate: dataPoint.views !== 0 ? (dataPoint.conversions / dataPoint.views * 100) : 0,
                    }));

                    const totalViews = chartDataWithRates.reduce((sum, item) => sum + item.views, 0);
                    const totalConversions = chartDataWithRates.reduce((sum, item) => sum + item.conversions, 0);
                    const conversionRate = totalViews !== 0 ? (totalConversions / totalViews * 100) : 0;

                    setUrlChartData(prevData => ({
                        ...prevData,
                        [selectedUrl]: {
                            chartData: chartDataWithRates,
                            views: totalViews,
                            conversions: totalConversions,
                            conversionRate,
                        },
                    }));
                    console.log(`111 Chart data loaded for: ${selectedUrl}`);
                } catch (error) {
                    console.error(`111 Error fetching chart data for ${selectedUrl}:`, error);
                }
            }
        };
        fetchChartDataForSelectedUrl();
    }, [selectedUrl]);

    const handleUrlChange = (value) => {
        setSelectedUrl(value);
        setShowWholeWebsiteWarning(value.includes("Whole website"));
        console.log(`111 URL selected by user: ${value}`);
    };

    return (
        <div data-seatextdont>
            <Form layout="vertical" style={{ margin: '20px' }}>
                {/* Main Container */}
                <div style={{ 
                    display: 'flex', 
                    gap: '40px', 
                    marginBottom: '20px', 
                    
                }}>
                    {/* Left Column */}
                    <div style={{ 
                        flex: 1, 
                        display: 'flex', 
                        flexDirection: 'column', 
                       
                       
                        width: '20%',
                       
                    }}>
                        {/* Title Section */}
                        <h2 style={{ 
                            fontSize: '32px', 
                            fontWeight: 500, 
                            marginBottom: '5px', 
                            marginTop: '0' 
                        }}>
                            Main AI Hub
                        </h2>
    
                        {/* Video Section */}
                        <div style={{ 
    flex: 1, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    backgroundColor: '#f8f8f8', 
    padding: '0', // Remove padding
    margin: '0', // Remove margin
   
   
    // Set fixed width (adjust as needed)
    overflow: 'hidden', // Ensure the video doesn't overflow the container
}}>
    <div style={{ 
        
    }}>
        <VideoPlayer 
            videoFileName="https://login.seatext.com/mainai.mp4" 
            style={{ 
                maxWidth: '450px',
                objectFit: 'cover', // Ensures the video scales properly
            }}
        />
    </div>
</div>
                    </div>
                   
                    {/* Right Column */}
                    <div data-seatextdont style={{ 
        flex: 1, // Add this to make the right column take up remaining space
        display: 'flex', 
        flexDirection: 'column', 
        gap: '20px',
        width: '80%', 
    }}>
                        {/* Explanation Section */}
                        <p style={{ 
                            fontSize: '14px', 
                            fontWeight: 400, 
                            color: '#666', 
                            marginTop: '0' 
                        }}>
                            You can activate different AI models for the entire website or for specific URLs. Please choose a URL below. If you see only one URL marked with the "Whole Website" tag, please wait at least one hour for the AI to index your pages. If, after one hour, you still see only one "Whole Website" URL, it means there was a problem during the SEATEXT AI installation, and SEATEXT was not properly added to your website. In this case, please contact support via chat, and we will immediately check what went wrong during installation.
                        </p>
   
                        {/* URL Chooser Section */}
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a URL"
                            value={selectedUrl}
                            onChange={handleUrlChange}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {urls.map(url => (
                                <Option value={url} key={url}>{url}</Option>
                            ))}
                        </Select>
                        
                        {/* Whole Website Warning */}
                        {showWholeWebsiteWarning && (
                            <div style={{ 
                                margin: '10px 0', 
                                padding: '10px', 
                                backgroundColor: '#f8f8f8', 
                                border: '1px solid #e8e8e8' 
                            }}>
                                You are editing settings for the entire website. Changes made here will affect all pages on your website,
                                including those added later. If you want to change settings for a particular URL and have specific settings for it,
                                please select a URL from the dropdown list above first.
                            </div>
                        )}
                        
                    </div>
                </div>
    
                {/* Additional Component (URLDataContainer or VariantEdit) */}
                {selectedUrl && urlChartData[selectedUrl] && (
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <URLDataContainer
                            key={selectedUrl}
                            url={selectedUrl}
                            conversionData={urlChartData[selectedUrl]}
                            carbonOptions={carbonOptions}
                            formatNumber={formatNumber}
                        />
                    </div>
                )}
            </Form>
        </div>
    );
}