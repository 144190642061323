import React, { useState, useEffect, useRef } from 'react';
import { Select, Form, Spin, Button } from 'antd';
import { getPageList, getLanguagesForUrl, getMediaVariantsForUrl, sendTranslatedMediaInfo, deleteTranslatedMediaInfo } from './utils';


const { Option } = Select;

function ImageUploader() {
    const [imageURLs, setImageURLs] = useState({});
    const [uploadingStatus, setUploadingStatus] = useState({});
    const [urls, setUrls] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState('');
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [mediaVariants, setMediaVariants] = useState([]);

    const cloudName = 'seatext';
    const uploadPreset = 'ml_default';

    
    useEffect(() => {
        const fetchPageList = async () => {
            const pageList = await getPageList();
            if (pageList) {
                setUrls(pageList);
            }
        };
        fetchPageList();
    }, []);

    useEffect(() => {
        const fetchLanguages = async () => {
            if (selectedUrl) {
                const languagesData = await getLanguagesForUrl(selectedUrl);
                if (languagesData && Array.isArray(languagesData)) {
                    setLanguages(languagesData);
                }
            }
        };
        fetchLanguages();
    }, [selectedUrl]);

    useEffect(() => {
        const fetchMediaVariants = async () => {
            if (selectedUrl && selectedLanguage) {
                const variantsData = await getMediaVariantsForUrl(selectedUrl, selectedLanguage);
                if (variantsData) {
                    console.log('Fetched media variants:', variantsData); // Log the fetched variants
                    
                    // Log the URLs to the console
                    variantsData.forEach(variant => {
                        console.log(`URL to show: ${variant.originalUrl}`);
                    });
    
                    setMediaVariants(variantsData);
                }
            }
        };
        fetchMediaVariants();
    }, [selectedUrl, selectedLanguage]);


    const handleUrlChange = (value) => {
        setSelectedUrl(value);
        setSelectedLanguage(''); // Reset selected language when URL changes
    };

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value);
    };

    const fileInputRefs = useRef({});

    const triggerFileInput = (mediaName) => {
        fileInputRefs.current[mediaName].click();
    };

    const handleImageError = (e, mediaName) => {
        console.error(`Error loading image: ${mediaName}`);
        e.target.style.display = 'none';
    };


    const handleDeleteTranslation = async (mediaName) => {
        try {
            const originalMediaUrl = mediaVariants.find(
                variant => variant.mediaName === mediaName
            )?.originalUrl;

            if (originalMediaUrl) {
                await deleteTranslatedMediaInfo(
                    selectedUrl,
                    selectedLanguage,
                    originalMediaUrl
                );

                // Remove the uploaded image from state
                setImageURLs(prevURLs => {
                    const newURLs = { ...prevURLs };
                    delete newURLs[mediaName];
                    return newURLs;
                });

                // Refresh the media variants
                const variantsData = await getMediaVariantsForUrl(selectedUrl, selectedLanguage);
                if (variantsData) {
                    setMediaVariants(variantsData);
                }
            }
        } catch (error) {
            console.error('Error deleting translation:', error);
        }
    };







    const handleImageUpload = async (e, mediaName) => {
        const file = e.target.files[0];
        if (file) {
            setUploadingStatus(prevStatus => ({ ...prevStatus, [mediaName]: true }));
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', uploadPreset);
    
            try {
                const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, {
                    method: 'POST',
                    body: formData
                });
    
                if (!response.ok) throw new Error('Upload failed');
    
                const data = await response.json();
                setImageURLs(prevURLs => ({ ...prevURLs, [mediaName]: data.secure_url }));
                setUploadingStatus(prevStatus => ({ ...prevStatus, [mediaName]: false }));
    
                // Find the original media URL
                const originalMediaUrl = mediaVariants.find(variant => variant.mediaName === mediaName)?.originalUrl;
    
                // Call the helper function to send the information to the server
                if (originalMediaUrl) {
                    await sendTranslatedMediaInfo(selectedUrl, selectedLanguage, originalMediaUrl, data.secure_url);
                } else {
                    console.error('Original media URL not found for', mediaName);
                }
    
            } catch (error) {
                console.error('Error in uploading image:', error);
                setUploadingStatus(prevStatus => ({ ...prevStatus, [mediaName]: false }));
            }
        }
    };


    return (
        <div>
            <Form layout="vertical">
                <Form.Item label="Page URL">
                    <Select value={selectedUrl} onChange={handleUrlChange}>
                        {urls.map((url, index) => (
                            <Option value={url} key={index}>{url}</Option>
                        ))}
                    </Select>
                </Form.Item>
                {selectedUrl && (
                    <Form.Item label="Language">
                        <Select value={selectedLanguage} onChange={handleLanguageChange}>
                            {languages.map((language, index) => (
                                <Option value={language} key={index}>{language}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
            </Form>
            {selectedUrl && selectedLanguage && (
                <>
            {mediaVariants.map((variant, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
            <img 
                src={variant.originalUrl} 
                alt={variant.mediaName} 
                onError={(e) => handleImageError(e, variant.mediaName)}
                style={{ width: '300px', marginRight: '10px' }} 
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Button onClick={() => triggerFileInput(variant.mediaName)}>
                    Upload Translated Picture
                </Button>
                {imageURLs[variant.mediaName] && (
                    <Button 
                        danger
                        onClick={() => handleDeleteTranslation(variant.mediaName)}
                    >
                        Delete Uploaded Picture
                    </Button>
                )}
            </div>
            <input 
                type="file" 
                ref={el => fileInputRefs.current[variant.mediaName] = el}
                onChange={(e) => handleImageUpload(e, variant.mediaName)}
                style={{ display: 'none' }}
            />
            {uploadingStatus[variant.mediaName] && <Spin style={{ marginRight: '10px' }} />}
            {imageURLs[variant.mediaName] && (
                <img 
                    src={imageURLs[variant.mediaName]} 
                    alt="Uploaded" 
                    style={{ width: '300px', marginLeft: '10px' }} 
                />
            )}
        </div>
    ))}
                </>
            )}
        </div>
    );
}

export default ImageUploader;