import React from 'react';
import { Card, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const FreePlanAdvice = () => {
  return (
    <Card
      style={{ 
        backgroundColor: '#fffbe6',
        border: '2px solid #ffe58f',
        marginBottom: '20px',
        maxWidth: '800px'
      }}
    >
      <div style={{ marginBottom: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <InfoCircleOutlined style={{ fontSize: '24px', color: '#ffa940', marginRight: '8px' }} />
        
        </div>
        <Text style={{ fontSize: '16px', lineHeight: '1.6', color: '#595959' }}>
          The Free Plan is our way of supporting millions of websites by offering basic translation services at no cost. It is perfect for non-commercial websites or users who want to explore SEATEXT AI’s capabilities without a financial commitment.
          <br /><br />
          However, with the Free Plan, you may encounter AI-generated text that is not always perfect. This is because we cannot run advanced AI filtering agents or utilize our most sophisticated models in the Free Plan. These features are essential for ensuring the high-quality content required for business-oriented websites.
          <br /><br />
          If you are running a commercial website, we highly recommend upgrading to the Paid Plan. It offers:
          <ul>
            <li>Comprehensive AI filtering for high-quality translations and copywriting</li>
            <li>Faster and more effective variant generation tailored to your brand voice</li>
            <li>Powerful Premium AI models proven to improve conversion rates without any limits</li>
          </ul>
          
        </Text>
      </div>
    </Card>
  );
};

export default FreePlanAdvice;
