import React, { useState } from 'react';
import { Button, Input, Card, Typography, Row, Col, message, Tooltip } from 'antd';
import Cookies from 'js-cookie';
import { sendRequest } from './utils'; // <--- Added import for sendRequest

const { Title, Text } = Typography;

const PaymentSection = () => {
  const [amount, setAmount] = useState(0); // State for top-up amount
  const [loading, setLoading] = useState(false); // State for loading during Stripe payment
  const [subscriptionLoading, setSubscriptionLoading] = useState(false); // State for loading during subscription cancellation

  // Handle predefined amount button clicks
  const handleAmountClick = (value) => {
    setAmount(value);
  };

  // Handle custom amount input change
  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setAmount(value);
    }
  };

  // Handle Stripe payment for top-up
  const handlePayWithStripe = async () => {
    if (amount <= 0) {
        message.error('Please enter a valid amount to top up.');
        return;
    }

    setLoading(true);

    try {
        const email = Cookies.get('email');
        if (!email) {
            throw new Error('Email not found in cookies.');
        }

        // Send the amount as a number (not a string)
        const response = await fetch('https://api.seatext.com:443/client/topup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, amount: Number(amount) }), // Convert amount to a number
        });

        if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        if (data.url) {
            // Redirect to Stripe payment page
            window.location.href = data.url;
        } else {
            throw new Error('No payment URL returned from the server.');
        }
    } catch (error) {
        console.error('Error processing payment:', error);
        message.error('Failed to process payment. Please try again.');
    } finally {
        setLoading(false);
    }
};

  // Handle adding a corporate card for metered usage
  const createSubscriptionLink = async (planId) => {
    const email = Cookies.get('email');
    if (!email) {
      message.error('Email not found in cookies.');
      return;
    }

    setLoading(true);

    try {
      const requestBody = { email, planId };
      const response = await fetch(`https://api.seatext.com:443/client/subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      if (data.url) {
        // Redirect to Stripe subscription page
        window.location.href = data.url;
      } else {
        throw new Error('No subscription URL returned from the server.');
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
      message.error('Failed to create subscription. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle canceling the Pay-as-you-go subscription
  const cancelSubscription = async () => {
    const email = Cookies.get('email');
    if (!email) {
      message.error('Email not found in cookies.');
      return;
    }

    setSubscriptionLoading(true);

    try {
      const response = await fetch(`https://api.seatext.com:443/client/subscription/cancel`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      if (data.success) {
        message.success('Subscription canceled successfully.');
      } else {
        throw new Error('Failed to cancel subscription.');
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      message.error('Failed to cancel subscription. Please try again.');
    } finally {
      setSubscriptionLoading(false);
    }
  };

  return (
    <div style={{ marginTop: '50px', width: '100%' }}>
      <Title level={3}>Move to Premium plan, get more conversions</Title>
      <Row gutter={24}>
        {/* Add Card Section */}
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Title level={4}>Add your card for Pay-as-you-go subscription</Title>
            <Text>
              Add your corporate card to enable metered usage. Seatext will automatically charge you based on your usage. Charges will be applied when your balance falls below -$10. As your credit history with Seatext builds, these charges will be consolidated and processed at the end of the week.
            </Text>
            <Button
              type="primary"
              onClick={() => createSubscriptionLink('price_1PcyPCFdMb3BNSidDkU25MJm')}
              style={{ marginTop: '20px', width: '100%' }}
              loading={loading}
            >
              Add your corporate card
            </Button>
            <Button
              type="danger"
              onClick={cancelSubscription}
              style={{ marginTop: '10px', width: '100%' }}
              loading={subscriptionLoading}
            >
              Cancel Pay-as-you-go Subscription
            </Button>
            <div style={{ marginTop: '10px' }}>
              <img
                src="https://images.ctfassets.net/fzn2n1nzq965/4M6d6BSWzlgsrJx8rdZb0I/733f37ef69b5ca1d3d33e127184f4ce4/Powered_by_Stripe.svg"
                alt="Powered by Stripe"
                width="150"
                height="34"
              />
            </div>
          </Card>
        </Col>

        {/* Top Up Balance Section */}
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Title level={4}>Add Funds to Your Balance</Title>
            <Text>
              Easily add a fixed amount to your balance as a one-time payment—no subscription required.
            </Text>
            <div style={{ marginTop: '20px' }}>
              <Button
                type={amount === 50 ? 'primary' : 'default'}
                onClick={() => handleAmountClick(50)}
                style={{ marginRight: '10px', marginBottom: '10px' }}
              >
                $50
              </Button>
              <Button
                type={amount === 100 ? 'primary' : 'default'}
                onClick={() => handleAmountClick(100)}
                style={{ marginRight: '10px', marginBottom: '10px' }}
              >
                $100
              </Button>
              <Button
                type={amount === 200 ? 'primary' : 'default'}
                onClick={() => handleAmountClick(200)}
                style={{ marginRight: '10px', marginBottom: '10px' }}
              >
                $200
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <Input
                placeholder="Enter custom amount"
                value={amount}
                onChange={handleCustomAmountChange}
                style={{ width: '200px', marginRight: '10px' }}
                type="number"
                min={0}
              />
              <Tooltip title="Enter the amount you wish to top up">
                <span style={{ cursor: 'pointer' }}>ℹ️</span>
              </Tooltip>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                onClick={handlePayWithStripe}
                style={{ backgroundColor: '#1890ff', color: 'white', transition: 'box-shadow 0.3s' }}
                onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 255, 0.5)'}
                onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
                loading={loading}
              >
                Pay with Stripe
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSection;