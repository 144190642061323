import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Button, message, Switch, Alert, Form, Select } from 'antd';
import { sendRequest, sendFeatureStatusToServer2, fetchSettingsFromServer, getPageListFromSettings } from './utils';
import Cookies from 'js-cookie';
import { InfoCircleOutlined } from '@ant-design/icons';
import LottieAnimation from './LottieAnimation';
import VideoPlayer from './VideoPlayer';
const { Title, Text } = Typography;
const { Option } = Select;

const styles = `
.settings-card {
  position: relative;
  height: 280px;
  padding: 28px;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
}

.settings-card-image {
  width: 354px;
  height: 224px;
  object-fit: cover;
  flex-shrink: 0;
}

.settings-card-content {
  margin-left: 56px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.settings-card-title {
  font-size: 22px;
  color: rgba(0,0,0,0.85);
  margin-bottom: 8px;
}

.settings-card-description {
  font-size: 16px;
  color: rgba(0,0,0,0.45);
  margin-bottom: 16px;
  flex: 1;
}

.settings-card-switch {
  display: flex;
  align-items: center;
  gap: 8px;
}

.settings-card-gray {
  background: #f5f5f5;
}
`;
const SettingsCard = ({ imageSrc, title, description, switchEnabled, onSwitchChange }) => (
  <Card 
    style={{ 
      marginBottom: '24px',
    }}
    bordered={true}
  >
    <div style={{ display: 'flex', gap: '24px' }}>
      <img 
        src={imageSrc} 
        alt={title} 
        style={{
          width: '354px',
          height: '224px',
          objectFit: 'cover',
          flexShrink: 0
        }}
      />
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1
      }}>
        <div>
          <h3 style={{ 
            fontSize: '22px', 
            color: 'rgba(0,0,0,0.85)',
            marginBottom: '16px'
          }}>{title}</h3>
          <p style={{ 
            fontSize: '16px', 
            color: 'rgba(0,0,0,0.65)',
            marginBottom: '16px'
          }}>{description}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Switch
            checked={switchEnabled}
            onChange={onSwitchChange}
            checkedChildren="ON"
            unCheckedChildren="OFF"
          />
          <span style={{ fontSize: '14px', color: 'rgba(0,0,0,0.45)' }}>
            {switchEnabled ? 'Enabled' : 'Disabled'}
          </span>
        </div>
      </div>
    </div>
  </Card>
);






















function SettingsPage2(props) {
  const defaultSettings = {
    AiABTestingEnabled: true,
    ABtestingCreativityLevel: 3,
    ABtestingVariantsTnumber: 5,
    ABtestingConfidenceLevel: 85,
    ABtestingEarlyElimination: 65,
    ABtestingBrandVoicePreservation: 5,
    ABtestingTrafficPercentageForVariants: 1,
    TitleTaglineEnhancementEnabled: true,
    WebsiteContentEnhancementEnabled: true,
    CTAsOptimizationEnabled: true,
    MenuItemsEnhancementEnabled: true,
    CustomerReviewsEnhancementEnabled: true,
    LegalDocumentationEnhancementEnabled: false,
    TechnicalDocumentationEnhancementEnabled: true,
    GeneralAIautoapproval: true,
  };

  const [settings, setSettings] = useState(defaultSettings);
  const [showAiABTestSwitch, setShowAiABTestSwitch] = useState(false);
  const [modifiedUrl, setModifiedUrl] = useState('');
  const [urls, setUrls] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [showWholeWebsiteWarning, setShowWholeWebsiteWarning] = useState(false);

  useEffect(() => {
    let initialUrl = props.context?.url || '';
    let isUrlFromCookies = false;

    if (!initialUrl) {
      const domainName = Cookies.get('domainName');
      if (domainName) {
        initialUrl = `${domainName} Whole website`;
        setShowAiABTestSwitch(true);
        isUrlFromCookies = true;
      }
    } else {
      setShowAiABTestSwitch(false);
    }

    setModifiedUrl(initialUrl);

    const fetchPageList = async () => {
      let pageList = await getPageListFromSettings();
      if (pageList && pageList.length > 0) {
        pageList.sort((a, b) => {
          if (a.includes("Whole website")) return -1;
          if (b.includes("Whole website")) return 1;
          return a.length - b.length;
        });

        const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
        if (wholeWebsiteUrls.length > 1) {
          const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
          pageList = pageList.filter(url => !url.includes("Whole website"));
          pageList.unshift(firstWholeWebsiteUrl);
        }

        setUrls(pageList);

        const wholeSiteUrl = pageList.find(url => url.includes("Whole website"));
        setSelectedUrl(wholeSiteUrl || pageList[0]);
        setShowWholeWebsiteWarning(!!wholeSiteUrl);
      }
    };

    fetchPageList();

    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(initialUrl);
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    }

    loadSettings();
  }, [props.context?.url]);

  const handleSettingChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    setSettings(updatedSettings);

    const payload = {
      url: selectedUrl || modifiedUrl,
      data: updatedSettings,
    };

    sendFeatureStatusToServer2(payload).catch(error => {
      console.error(error);
      message.error('Failed to update settings.');
    });
  };

  const handleUrlChange = (value) => {
    setSelectedUrl(value);
    setShowWholeWebsiteWarning(value.includes("Whole website"));
    setModifiedUrl(value);

    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(value);
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    }

    loadSettings();
  };

  const cardsData = [
    {
      imageSrc: "https://login.seatext.com/titles-taglines.png",
      title: "Titles, Taglines, and Short Phrases",
      description: "Enhance your website's titles, taglines, and short phrases with AI. This feature uses AI's contextual understanding to complement your content. Our AI tailors its suggestions based on your industry and the copy on your website.",
      settingKey: "TitleTaglineEnhancementEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/website-content.png",
      title: "Website Content Enhancement",
      description: "Enhance your website content using AI to boost user engagement. Adjust the AI Creativity Level to match your brand's voice and tone. Our AI will generate at least six different variants and track their performance through extensive A/B testing.",
      settingKey: "WebsiteContentEnhancementEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/ctas-buttons.png",
      title: "Calls-to-Action (CTAs) and Buttons",
      description: "AI rewrites calls to action and buttons based on your website's content and industry. This feature enables you to A/B test multiple options, helping you determine the most effective CTAs to convert your target audience and buyer personas.",
      settingKey: "CTAsOptimizationEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/menu-items.png",
      title: "Menu items",
      description: "Utilize AI to refine your menu item descriptions, making them more engaging and clear. Due to the concise nature of menu texts, it is essential to prioritize accuracy.",
      settingKey: "MenuItemsEnhancementEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/customer-reviews.png",
      title: "Customer reviews",
      description: "Our AI technology refines customer reviews by improving language, correcting grammatical errors, and moderating tone. It enhances the readability and professionalism of the reviews while preserving the original sentiment.",
      settingKey: "CustomerReviewsEnhancementEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/license-agreement-documentation.png",
      title: "License Agreements",
      description: "AI can assist in reviewing and suggesting revisions to license agreements and other legal documents. However, due to the critical nature of these documents, we strongly advise against activating this feature without thorough oversight.",
      settingKey: "LegalDocumentationEnhancementEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/technical-documentation-1.png",
      title: "Technical Documentation",
      description: "Our AI can assist in refining the presentation of technical information on your website. It carefully rephrases content to improve clarity and understanding, which is particularly beneficial for software companies and other tech-focused entities.",
      settingKey: "TechnicalDocumentationEnhancementEnabled"
    }
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '100%', minWidth: '1086px' }}>
        <Card bordered={false}>
        <div className="ai-card-content" style={{ 
  marginBottom: '30px',
  marginTop: '0px',
  backgroundColor: '#f8f8f8',
  height: '500px', // Change from minHeight to fixed height
  padding: '20px',
  border: '1px solid #A9A9A9',
  display: 'flex',
  gap: '40px',
  position: 'relative', // Add this
 minHeight: "700px"


}}>
  {/* Left Column */}
  <div style={{ 
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }}>
    <h2 className="ai-title" style={{ 
      fontSize: '32px',
      marginBottom: '20px',
      marginTop: '0'
    }}>
      General AI Model: improves any website sales and conversion rates
    </h2>
    
 
    


  <div style={{ 
                    flex: 1,
                    display: 'flex', // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                  }}>
                    <div style={{ width: '100%', aspectRatio: '16/9' }}>
                      <VideoPlayer videoFileName="https://login.seatext.com/Variant-Editor.mp4" />
                    </div>
                  </div>

                  </div>




  {/* Right Column remains unchanged */}

  {/* Right Column - Remove any padding/margin at top */}
  <div style={{ 
    flex: 1,
    paddingTop: '0', // Ensure no padding at top
    marginTop: '0' // Ensure no margin at top
  }}>
    {/* Keep existing content structure */}
    <div className="ai-description" style={{ 
      fontSize: '16px', 
      color: 'rgba(0,0,0,0.65)',
      lineHeight: '1.6',
      marginTop: '0px',
      paddingTop: '0' // Ensure no padding
    }}>
               SEATEXT AI boosts your sales by generating over 1,000 text variants for your website, just like a professional copywriter. It then conducts automatic A/B testing on these variants, replicating the expertise of an online marketing professional to identify which ones effectively increase your conversion rates and sales. This AI model is compatible with any website and can access up to 90% of the text on your site. You can customize which text the AI can or cannot fine-tune below. Note that the Free plan comes with significant limitations. Upgrade to the Premium plan to unlock its full potential.

{/* AI Activation Section */}
<div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', gap: '16px' }}>
  <Switch
    checked={settings.AiABTestingEnabled}
    onChange={(checked) => handleSettingChange('AiABTestingEnabled', checked)}
    style={{ minWidth: '40px' }}
  />
  <Text strong style={{ fontSize: '16px' }}>
    Activate General AI Model
  </Text>
</div>

{/* Auto-approval Section */}
<div style={{ marginTop: '24px' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
    <Switch
      checked={settings.GeneralAIautoapproval}
      onChange={(checked) => handleSettingChange('GeneralAIautoapproval', checked)}
      style={{ minWidth: '40px' }}
    />
    <Text strong style={{ fontSize: '16px' }}>
      Automatically Approve AI Variants
    </Text>
  </div>
  <div style={{ marginTop: '8px', marginLeft: '56px' }}>
    <Text type="secondary" style={{ fontSize: '14px' }}>
      Turn off to manually review variants before they go live. 
      
        Warning: Manual review may require significant time investment.
  
    </Text>
  </div>
</div>
<div  data-seatextdont data-seatexttranslateoff>
                <Form layout="vertical" style={{ marginTop: '20px' }}>
               
                  <Select
                    showSearch
                    style={{ width: '100%', marginBottom: '20px' }}
                    placeholder="Select a URL"
                    value={selectedUrl}
                    onChange={handleUrlChange}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {urls.map(url => (
                      <Option value={url} key={url}>{url}</Option>
                    ))}
                  </Select>
                 
                  {showWholeWebsiteWarning && (
                    <Alert
                      message="Whole Website Settings"
                      description="You are editing settings for the entire website. Changes made here will affect all pages on your website, including those added later."
                      type="info"
                      showIcon
                      icon={<InfoCircleOutlined />}
                    />
                  )}
                </Form>
                </div>
              </div>
            </div>
          </div>
{/* Control Methods Section */}
{/* Method 1 Card */}

<Card 
  style={{ 
    marginBottom: '30px',
    background: '#f8f8f8',
    border: '1px solid #e8e8e8'
  }}
>
<Title level={2} style={{ marginBottom: '16px' }}>
        
        Method 1: Control what text AI optimizes with Special Tags
       </Title>
  
  <div style={{ display: 'flex', gap: '40px' }}>
    <img 
      src="https://login.seatext.com/2.jpg" 
      alt="SEA Text Tags" 
      style={{ 
        width: '380px',
        objectFit: 'cover',
        flexShrink: 0
      }} 
    />
    <div>
      <h5 style={{ 
        fontSize: '18px', 
        marginBottom: '16px',
        color: 'rgba(0,0,0,0.85)'
      }}>
       
      </h5>
      <p style={{ 
        fontSize: '16px', 
        color: 'rgba(0,0,0,0.65)',
        lineHeight: '1.5'
      }}>
        The best way to control AI optimization is by using special tags. We recommend using the `seatextdont` and 'seatextonly' special words inside your website editor as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. This method offers the most precise control over AI's behavior.
      </p>
    </div>
  </div>
</Card>

{/* Method 2 Introduction Card */}
{/* Method 2: AI Optimization Settings */}
<Card 
  style={{ 
    marginBottom: '30px',
    background: '#f5f5f5',
    padding: '24px'
  }}
>
  <Title level={2} style={{ marginBottom: '16px' }}>
    Method 2: Choose Website Sections for AI Optimization (less precise)
  </Title>
  <Text style={{ 
    fontSize: '16px',
    color: 'rgba(0,0,0,0.65)',
    display: 'block',
    marginBottom: '24px'
  }}>
    If you can't use the special tags or prefer a quicker approach, you can use the switches below to select specific sections of your website for AI optimization.
  </Text>

  {cardsData.map((card, index) => (
    <Card 
      key={index}
      style={{ 
        marginBottom: '16px',
        background: index % 2 === 0 ? '#ffffff' : '#f8f8f8'
      }}
    >
      <div style={{ display: 'flex', gap: '24px' }}>
        <img 
          src={card.imageSrc} 
          alt={card.title} 
          style={{
            width: '354px',
            height: '224px',
            objectFit: 'cover',
            flexShrink: 0
          }}
        />
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1
        }}>
          <div>
            <Title level={3} style={{ 
              fontSize: '22px',
              marginBottom: '16px',
              marginTop: 0
            }}>{card.title}</Title>
            <Text style={{ 
              fontSize: '16px',
              color: 'rgba(0,0,0,0.65)',
              display: 'block',
              marginBottom: '16px'
            }}>{card.description}</Text>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Switch
              checked={settings[card.settingKey]}
              onChange={(checked) => handleSettingChange(card.settingKey, checked)}
              checkedChildren="ON"
              unCheckedChildren="OFF"
            />
            <Text style={{ fontSize: '14px', color: 'rgba(0,0,0,0.45)' }}>
              {settings[card.settingKey] ? 'Enabled' : 'Disabled'}
            </Text>
          </div>
        </div>
      </div>
    </Card>
  ))}
</Card>



          {/* Sliders Section */}
          <div style={{ marginTop: '30px' }}>
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>AI Creativity Level:</Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  marks={{
                    1: 'Min', 
                    2: 'Low',
                    3: 'Medium',
                    4: 'High',
                    5: 'Max', 
                  }}
                  onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
                  value={settings.ABtestingCreativityLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how creative the AI should be. A higher setting means the AI will generate content that's significantly different from the original, while a lower setting limits creativity to minimal changes. 
                </Text>
              </Col>
            </Row>

            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Number of Variants to Create:</Text>
                <Slider
                  min={2}
                  max={8}
                  step={1}
                  marks={{
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
                  value={settings.ABtestingVariantsTnumber}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                </Text>
              </Col>
            </Row>

            <Row gutter={[16, 24]} style={{ marginBottom: '150px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Preserve Your Brand Voice (0-10):</Text>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
                  value={settings.ABtestingBrandVoicePreservation}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                  }}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                </Text>
              </Col>
            </Row>

            <Title level={2} align="center" style={{ marginBottom: '50px' }}>A/B Testing Settings</Title>

            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Confidence Level for A/B Testing:</Text>
                <Slider
                  min={70}
                  max={95}
                  step={5}
                  marks={{
                    70: '70%',
                    75: '75%',
                    80: '80%',
                    85: '85% Recommended',
                    90: '90%',
                    95: '95%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
                  value={settings.ABtestingConfidenceLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                </Text>
              </Col>
            </Row>

            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Early Elimination of Underperforming Variants:</Text>
                <Slider
                  min={60}
                  max={70}
                  step={2}
                  marks={{
                    60: '60%',
                    62: '62%',
                    65: '65% Recommended',
                    67: '67%',
                    70: '70%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
                  value={settings.ABtestingEarlyElimination}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
                </Text>
              </Col>
            </Row>

            <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Introduce AI variants slowly to your visitors:</Text>
                <Slider
                  min={1}
                  max={6}
                  step={1}
                  marks={{
                    1: '100%',
                    2: '80%',
                    3: '60%',
                    4: '40%',
                    5: '10%',
                    6: '0% ',
                  }}
                  defaultValue={1}
                  onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This percentage of traffic should see AI variants, put it to 10% if you need to introduce AI variants to your visitors very slowly. Your original text will be tested in A/B mix in any case as one of variant.
                </Text>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SettingsPage2;