import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Card, 
  Row, 
  Col, 
  Slider, 
  Switch, 
  Select, 
  Form, 
  message, 
  Alert  // Added Alert import
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';  // Added icon import
import Cookies from 'js-cookie';
import { sendFeatureStatusToServer2, fetchSettingsFromServer, getPageListFromSettings } from './utils';
import VideoPlayer from './VideoPlayer';
import "./style.css";

const { Title, Text } = Typography;
const { Option } = Select;

function Etrade(props) {
  const defaultSettings = {
    ProductDescOptEnabled: true,
    ProductNameCustomizationEnabled: true,
    BrandMessagingOptimizationEnabled: true,
    CustomerReviewsEnhancementEnabled: true,
    EnhancedTechnicalDetailsEnabled: true,
    IngredientQualityDescriptionsEnabled: true,
    PackagingTextRefinementEnabled: true,
    DynamicProductDescriptionsEnabled: true,
    InteractiveButtonTextsEnabled: true,
    SmallTaglinesEnabled: true,
    ABtestingCreativityLevel: 3,
    ABtestingVariantsTnumber: 5,
    ABtestingConfidenceLevel: 85,
    ABtestingEarlyElimination: 65,
    ABtestingBrandVoicePreservation: 5,
    ABtestingTrafficPercentageForVariants: 1,
    EtradeAIautoallowed: true,
  };

  // State management
  const [settings, setSettings] = useState(defaultSettings);
  const [showAiABTestSwitch, setShowAiABTestSwitch] = useState(false);
  const [modifiedUrl, setModifiedUrl] = useState('');
  const [urls, setUrls] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [showWholeWebsiteWarning, setShowWholeWebsiteWarning] = useState(false);

  useEffect(() => {
    let initialUrl = props.context?.url || '';
    let isUrlFromCookies = false;

    if (!initialUrl) {
      const domainName = Cookies.get('domainName');
      if (domainName) {
        initialUrl = `${domainName} Whole website`;
        setShowAiABTestSwitch(true);
        isUrlFromCookies = true;
      }
    } else {
      setShowAiABTestSwitch(false);
    }

    setModifiedUrl(initialUrl);

    const fetchPageList = async () => {
      let pageList = await getPageListFromSettings();
      if (pageList && pageList.length > 0) {
        pageList.sort((a, b) => {
          if (a.includes("Whole website")) return -1;
          if (b.includes("Whole website")) return 1;
          return a.length - b.length;
        });

        const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
        if (wholeWebsiteUrls.length > 1) {
          const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
          pageList = pageList.filter(url => !url.includes("Whole website"));
          pageList.unshift(firstWholeWebsiteUrl);
        }

        setUrls(pageList);

        const wholeSiteUrl = pageList.find(url => url.includes("Whole website"));
        setSelectedUrl(wholeSiteUrl || pageList[0]);
        setShowWholeWebsiteWarning(!!wholeSiteUrl);
      }
    };

    fetchPageList();

    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(initialUrl);
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    }

    loadSettings();
  }, [props.context?.url]);

  const handleSettingChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    setSettings(updatedSettings);

    const payload = {
      url: selectedUrl || modifiedUrl,
      data: updatedSettings,
    };

    sendFeatureStatusToServer2(payload).catch(error => {
      console.error(error);
      message.error('Failed to update settings.');
    });
  };

  const handleUrlChange = (value) => {
    setSelectedUrl(value);
    setShowWholeWebsiteWarning(value.includes("Whole website"));
    setModifiedUrl(value);

    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(value);
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    }

    loadSettings();
  };

  const cardsData = [
    {
      imageSrc: "https://login.seatext.com/product-name-customization-img-1.png",
      title: "Product Name Customization",
      description: "Enable AI to enhance product names by appending descriptors such as 'innovative,' 'refreshing,' or 'robust.' This feature leverages AI's contextual understanding to aptly complement your product names. However, be aware that some suggestions may be suboptimal and require manual review.",
      settingKey: "ProductNameCustomizationEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/brand-messaging-optimization-img-1.png",
      title: "Brand Messaging Optimization",
      description: "AI can subtly refine your brand messages and slogans without overhauling the core message. As the most impactful yet sensitive tool, we recommend using it with caution. Monitor the variant editor to review the AI's outputs and their effectiveness.",
      settingKey: "BrandMessagingOptimizationEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/customer-reviews-enhancement-img-1.png",
      title: "Customer Reviews Enhancement",
      description: "Our AI can refine customer reviews by improving language, correcting grammatical errors, and moderating tone. It enhances readability and professionalism without altering the original sentiment. Note: If you wish to remove negative reviews, this must be done manually.",
      settingKey: "CustomerReviewsEnhancementEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/enhanced-technical-details-img-1.png",
      title: "Enhanced Technical Details",
      description: "Improve your product's technical specifications with AI. It can optimize language and adapt measurements to relevant units (e.g., converting to imperial units for the US market). Rest assured, numerical data remains unchanged as we prioritize accuracy.",
      settingKey: "EnhancedTechnicalDetailsEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/ingredient-and-quality-descriptions-img-1.png",
      title: "Ingredient and Quality Descriptions",
      description: "AI can fine-tune descriptions related to ingredients and manufacturing quality, aligning closely with your original style to preserve your brand's identity.",
      settingKey: "IngredientQualityDescriptionsEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/packaging-text-refinement-img-1.png",
      title: "Packaging Text Refinement",
      description: "AI can fine-tune the text on your packaging, which is crucial for international shipments or when adding special items. For luxury packaging, precise text adjustments can significantly enhance your brand's presentation.",
      settingKey: "PackagingTextRefinementEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/dynamic-product-descriptions-img-1.png",
      title: "Dynamic Product Descriptions",
      description: "Our AI can generate variations of your product descriptions, emphasizing different benefits. This allows you to discover what resonates most with your customers and refine your messaging accordingly.",
      settingKey: "DynamicProductDescriptionsEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/interactive-button-texts-img-1.png",
      title: "Interactive Button Texts",
      description: "AI can analyze and test various texts for interactive buttons like 'Buy Now.' It generates thousands of alternatives, presenting a unique version to each user per session.",
      settingKey: "InteractiveButtonTextsEnabled"
    },
    {
      imageSrc: "https://login.seatext.com/small-taglines-img-1.png",
      title: "Small Taglines",
      description: "Small taglines play a crucial role in capturing customer interest. Our AI can generate several variations of your existing tagline and create new ones inspired by the content of your page.",
      settingKey: "SmallTaglinesEnabled"
    }
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '100%', minWidth: '1086px' }}>
        <Card bordered={false}>
          {/* Updated Top Section with Video */}
          <div className="ai-card-content" style={{ 
            marginBottom: '30px',
            marginTop: '0px',
            backgroundColor: '#f8f8f8',
            height: '500px',
            padding: '20px',
            border: '1px solid #A9A9A9',
            display: 'flex',
            gap: '40px',
            position: 'relative',
            minHeight: "600px"
          }}>
            {/* Left Column */}
            <div style={{ 
              flex: 1,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <h2 className="ai-title" style={{ 
                fontSize: '32px',
                marginBottom: '20px',
                marginTop: '0'
              }}>
                AI for online stores
              </h2>
              
              <div style={{ 
                    flex: 1,
                    display: 'flex', // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                  }}>
                    <div style={{ width: '100%', aspectRatio: '16/9' }}>
                      <VideoPlayer videoFileName="https://login.seatext.com/Variant-Editor.mp4" />
                    </div>
                  </div>

                  </div>


            {/* Right Column */}
            <div style={{ 
              flex: 1,
              paddingTop: '0',
              marginTop: '0'
            }}>
              <div className="ai-description" style={{ 
                fontSize: '16px', 
                color: 'rgba(0,0,0,0.65)',
                lineHeight: '1.6',
                paddingTop: '0'
              }}>
                This AI's core feature is boosting online store sales by optimizing product descriptions using AI. 
                It automatically generates and A/B tests multiple versions of product descriptions, identifying the 
                most compelling language to drive purchases. This results in significant sales increases of up to 40%. 
                Its unique strength lies in its specialized Large Language Model trained specifically on high-converting 
                e-commerce content.

                {/* AI Activation Section */}
                <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <Switch
                    checked={settings.ProductDescOptEnabled}
                    onChange={(checked) => handleSettingChange('ProductDescOptEnabled', checked)}
                    style={{ minWidth: '40px' }}
                  />
                  <Text strong style={{ fontSize: '16px' }}>
                    Activate E-commerce AI Model
                  </Text>
                </div>

                {/* Auto-approval Section */}
                <div style={{ marginTop: '24px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <Switch
                      checked={settings.EtradeAIautoallowed}
                      onChange={(checked) => handleSettingChange('EtradeAIautoallowed', checked)}
                      style={{ minWidth: '40px' }}
                    />
                    <Text strong style={{ fontSize: '16px' }}>
                      Automatically Approve AI Variants
                    </Text>
                  </div>
                  <div style={{ marginTop: '8px', marginLeft: '56px' }}>
                    <Text type="secondary" style={{ fontSize: '14px' }}>
                      Turn off to manually review variants before they go live. 
                      Warning: Manual review may require significant time investment.
                    </Text>
                  </div>
                </div>

                <Form layout="vertical" style={{ marginTop: '20px' }}>
                  <Select
                    showSearch
                    style={{ width: '100%', marginBottom: '20px' }}
                    placeholder="Select a URL"
                    value={selectedUrl}
                    onChange={handleUrlChange}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {urls.map(url => (
                      <Option value={url} key={url}>{url}</Option>
                    ))}
                  </Select>
                  {showWholeWebsiteWarning && (
                    <Alert
                      message="Whole Website Settings"
                      description="You are editing settings for the entire website. Changes made here will affect all pages on your website, including those added later."
                      type="info"
                      showIcon
                      icon={<InfoCircleOutlined />}
                    />
                  )}
                </Form>
              </div>
            </div>
          </div>

          {/* Method 1 Card */}
          <Card 
            style={{ 
              marginBottom: '30px',
              background: '#f8f8f8',
              border: '1px solid #e8e8e8'
            }}
          >
            <Title level={2} style={{ marginBottom: '16px' }}>
        
             Method 1: Control what text AI optimizes with Special Tags
            </Title>
            
            <div style={{ display: 'flex', gap: '40px' }}>
              <img 
                src="https://login.seatext.com/2.jpg" 
                alt="SEA Text Tags" 
                style={{ 
                  width: '380px',
                  objectFit: 'cover',
                  flexShrink: 0
                }} 
              />
              <div>
                <h5 style={{ 
                  fontSize: '18px', 
                  marginBottom: '16px',
                  color: 'rgba(0,0,0,0.85)'
                }}>
                  
                </h5>
                <p style={{ 
                  fontSize: '16px', 
                  color: 'rgba(0,0,0,0.65)',lineHeight: '1.5'
                }}>
                  The best way to control AI optimization is by using special tags. We recommend using the `seatextdont` and 'seatextonly' special words inside your website editor as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. This method offers the most precise control over AI's behavior.
                </p>
              </div>
            </div>
          </Card>

          {/* Method 2: AI Optimization Settings */}
          <Card 
            style={{ 
              marginBottom: '30px',
              background: '#f5f5f5',
              padding: '24px'
            }}
          >
            <Title level={2} style={{ marginBottom: '16px' }}>
              Method 2: Choose Website Sections for AI Optimization (less precise)
            </Title>
            <Text style={{ 
              fontSize: '16px',
              color: 'rgba(0,0,0,0.65)',
              display: 'block',
              marginBottom: '24px'
            }}>
              If you can't use the special tags or prefer a quicker approach, you can use the switches below to select specific sections of your website for AI optimization.
            </Text>

            {cardsData.map((card, index) => (
              <Card 
                key={index}
                style={{ 
                  marginBottom: '16px',
                  background: index % 2 === 0 ? '#ffffff' : '#f8f8f8'
                }}
              >
                <div style={{ display: 'flex', gap: '24px' }}>
                  <img 
                    src={card.imageSrc} 
                    alt={card.title} 
                    style={{
                      width: '354px',
                      height: '224px',
                      objectFit: 'cover',
                      flexShrink: 0
                    }}
                  />
                  <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flex: 1
                  }}>
                    <div>
                      <Title level={3} style={{ 
                        fontSize: '22px',
                        marginBottom: '16px',
                        marginTop: 0
                      }}>{card.title}</Title>
                      <Text style={{ 
                        fontSize: '16px',
                        color: 'rgba(0,0,0,0.65)',
                        display: 'block',
                        marginBottom: '16px'
                      }}>{card.description}</Text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Switch
                        checked={settings[card.settingKey]}
                        onChange={(checked) => handleSettingChange(card.settingKey, checked)}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                      />
                      <Text style={{ fontSize: '14px', color: 'rgba(0,0,0,0.45)' }}>
                        {settings[card.settingKey] ? 'Enabled' : 'Disabled'}
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </Card>

          {/* Sliders Section */}
          <div style={{ marginTop: '30px' }}>
            <Title level={2} style={{ marginBottom: '24px' }}>General AI Settings</Title>

            {/* AI Creativity Level */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>AI Creativity Level:</Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  marks={{
                    1: 'Max Creativity',
                    2: 'High',
                    3: 'Medium',
                    4: 'Low',
                    5: 'Minimal Changes',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
                  value={settings.ABtestingCreativityLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how creative the AI should be. A lower setting means the AI will generate content that's significantly different from the original, while a higher setting limits creativity to minimal changes.
                </Text>
              </Col>
            </Row>

            {/* Number of Variants */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Number of Variants to Create:</Text>
                <Slider
                  min={2}
                  max={8}
                  step={1}
                  marks={{
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
                  value={settings.ABtestingVariantsTnumber}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                </Text>
              </Col>
            </Row>

            {/* Brand Voice Preservation */}
            <Row gutter={[16, 24]} style={{ marginBottom: '150px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Preserve Your Brand Voice (0-10):</Text>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
                  value={settings.ABtestingBrandVoicePreservation}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                  }}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                </Text>
              </Col>
            </Row>

            {/* A/B Testing Settings */}
            <Title level={2} style={{ marginBottom: '24px' }}>A/B Testing Settings</Title>

            {/* Confidence Level */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Confidence Level for A/B Testing:</Text>
                <Slider
                  min={70}
                  max={95}
                  step={5}
                  marks={{
                    70: '70%',
                    75: '75%',
                    80: '80%',
                    85: '85% Recommended',
                    90: '90%',
                    95: '95%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
                  value={settings.ABtestingConfidenceLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                </Text>
              </Col>
            </Row>

            {/* Early Elimination */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Early Elimination of Underperforming Variants:</Text>
                <Slider
                  min={60}
                  max={70}
                  step={1}
                  marks={{
                    60: '60%',
                    62: '62%',
                    65: '65% Recommended',
                    67: '67%',
                    70: '70%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
                  value={settings.ABtestingEarlyElimination}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
                </Text>
              </Col>
            </Row>

            {/* Traffic Percentage */}
            <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Introduce AI variants slowly to your visitors:</Text>
                <Slider
                  min={1}
                  max={6}
                  step={1}
                  marks={{
                    1: '100%',
                    2: '80%',
                    3: '60%',
                    4: '40%',
                    5: '10%',
                    6: '0%',
                  }}
                  defaultValue={1}
                  onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This percentage of traffic should see AI variants, put it to 10% if you need to introduce AI variants to your visitors very slowly. Your original text will be tested in A/B mix in any case as one of variant.
                </Text>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Etrade;