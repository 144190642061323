import React, { useState, useRef } from 'react';
import { Modal } from 'antd';
import { PlayCircleFilled, CloseCircleFilled } from '@ant-design/icons';

// Static thumbnail URL (you can replace this with your actual thumbnail URL)
const STATIC_THUMBNAIL_URL = 'https://login.seatext.com/thumb.png';

const VideoPlayer = ({ videoFileName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);

  const handleOpen = () => {
    setIsModalOpen(true);

    // Fallback for browsers that block autoplay
    setTimeout(() => {
      if (videoRef.current) {
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch(() => {
            videoRef.current.controls = true;
          });
        }
      }
    }, 100);
  };

  const handleClose = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    setIsModalOpen(false);
  };

  return (
    <div className="video-player-container">
      {/* Thumbnail View */}
      <div className="thumbnail-container" onClick={handleOpen}>
        <img
          src={STATIC_THUMBNAIL_URL} // Use the static thumbnail URL
          alt="Video Thumbnail"
          className="thumbnail-image"
        />
        <div className="play-overlay">
          <PlayCircleFilled className="play-icon" />
        </div>
      </div>

      {/* Modal View */}
      <Modal
        open={isModalOpen}
        onCancel={handleClose}
        footer={null}
        closable={false} // Disable the default close button
        width="100%"
        style={{
          top: 0, // Remove any default top spacing
          padding: 0, // Remove padding
          margin: 0, // Remove margin
          maxWidth: '100%', // Ensure modal takes full width
        }}
        bodyStyle={{
          padding: 0, // Remove padding
          margin: 0, // Remove margin
          height: '100vh', // Full height
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent', // Transparent background
        }}
        centered
        destroyOnClose
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <video
            ref={videoRef}
            src={videoFileName}
            controls
            autoPlay
            className="fullscreen-video"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Ensure video fills the space
            }}
          />
          {/* Custom Floating Close Button */}
          <CloseCircleFilled
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              fontSize: '24px',
              color: 'white',
              cursor: 'pointer',
              zIndex: 1000,
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a subtle background for better visibility
              borderRadius: '50%',
              padding: '5px',
            }}
          />
        </div>
      </Modal>

      <style jsx>{`
        .thumbnail-container {
          position: relative;
          cursor: pointer;
          max-width: 800px;
          margin: 0 auto;
          border-radius: 8px;
          overflow: hidden;
          transition: transform 0.2s;
        }

        .thumbnail-container:hover {
          transform: scale(1.02);
        }

        .thumbnail-image {
          width: 100%;
          height: auto;
          display: block;
        }

        .play-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.4);
        }

        .play-icon {
          font-size: 72px;
          color: rgba(255, 255, 255, 0.9);
          transition: all 0.3s;
        }

        .play-icon:hover {
          color: white;
          transform: scale(1.1);
        }

        .fullscreen-video {
          object-fit: cover;
        }
      `}</style>
    </div>
  );
};

export default VideoPlayer;